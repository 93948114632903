exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-1000-days-of-glazed-mdx": () => import("./../../../src/pages/blog/1000-days-of-glazed.mdx" /* webpackChunkName: "component---src-pages-blog-1000-days-of-glazed-mdx" */),
  "component---src-pages-blog-chatbots-what-why-how-pt-1-mdx": () => import("./../../../src/pages/blog/chatbots-what-why-how-pt1.mdx" /* webpackChunkName: "component---src-pages-blog-chatbots-what-why-how-pt-1-mdx" */),
  "component---src-pages-blog-chatbots-what-why-how-pt-2-mdx": () => import("./../../../src/pages/blog/chatbots-what-why-how-pt2.mdx" /* webpackChunkName: "component---src-pages-blog-chatbots-what-why-how-pt-2-mdx" */),
  "component---src-pages-blog-chatbots-what-why-how-pt-3-mdx": () => import("./../../../src/pages/blog/chatbots-what-why-how-pt3.mdx" /* webpackChunkName: "component---src-pages-blog-chatbots-what-why-how-pt-3-mdx" */),
  "component---src-pages-blog-distributed-background-jobs-mdx": () => import("./../../../src/pages/blog/distributed-background-jobs.mdx" /* webpackChunkName: "component---src-pages-blog-distributed-background-jobs-mdx" */),
  "component---src-pages-blog-efficient-computations-and-database-usage-mdx": () => import("./../../../src/pages/blog/efficient-computations-and-database-usage.mdx" /* webpackChunkName: "component---src-pages-blog-efficient-computations-and-database-usage-mdx" */),
  "component---src-pages-blog-health-and-tech-in-2021-mdx": () => import("./../../../src/pages/blog/health-and-tech-in-2021.mdx" /* webpackChunkName: "component---src-pages-blog-health-and-tech-in-2021-mdx" */),
  "component---src-pages-blog-how-we-built-a-website-in-12-hours-mdx": () => import("./../../../src/pages/blog/how-we-built-a-website-in-12-hours.mdx" /* webpackChunkName: "component---src-pages-blog-how-we-built-a-website-in-12-hours-mdx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-perils-of-the-browser-back-button-mdx": () => import("./../../../src/pages/blog/perils-of-the-browser-back-button.mdx" /* webpackChunkName: "component---src-pages-blog-perils-of-the-browser-back-button-mdx" */),
  "component---src-pages-blog-rapid-delivery-apps-mdx": () => import("./../../../src/pages/blog/rapid-delivery-apps.mdx" /* webpackChunkName: "component---src-pages-blog-rapid-delivery-apps-mdx" */),
  "component---src-pages-blog-react-native-live-activities-ios-mdx": () => import("./../../../src/pages/blog/react-native-live-activities-ios.mdx" /* webpackChunkName: "component---src-pages-blog-react-native-live-activities-ios-mdx" */),
  "component---src-pages-blog-tale-of-missing-font-weight-mdx": () => import("./../../../src/pages/blog/tale-of-missing-font-weight.mdx" /* webpackChunkName: "component---src-pages-blog-tale-of-missing-font-weight-mdx" */),
  "component---src-pages-case-studies-hygraph-case-study-slug-tsx": () => import("./../../../src/pages/case-studies/{HygraphCaseStudy.slug}.tsx" /* webpackChunkName: "component---src-pages-case-studies-hygraph-case-study-slug-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-next-tsx": () => import("./../../../src/pages/next.tsx" /* webpackChunkName: "component---src-pages-next-tsx" */)
}

